// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Hello from './pages/hello';
import Chat from './pages/assistant';
import AiPhone from './pages/aiphone';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col h-screen">
        {/* 导航栏 */}
        <nav className="bg-gray-800 p-4">
          <ul className="flex space-x-4 text-white">
            <li>
              <Link to="/" className="hover:underline">Hello</Link>
            </li>
            <li>
              <Link to="/chat" className="hover:underline">对话</Link>
            </li>
            <li>
              <Link to="/aiphone" className="hover:underline">AiPhone</Link>
            </li>
          </ul>
        </nav>

        {/* 路由视图 */}
        <div className="flex-grow overflow-auto">
          <Routes>
            <Route path="/" element={<Hello />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/aiphone" element={<AiPhone />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
