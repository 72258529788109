import React, { useState, useEffect, useCallback } from 'react';

const MobileApp = () => {
  const [homePage, setHomePage] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [customOption, setCustomOption] = useState('');
  const [timeLine, setTimeLine] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState('');
  const [isChoices, setIsChoices] = useState(false);

  const token = localStorage.getItem('token'); // 获取 JWT 令牌

  // 将 init 函数用 useCallback 包裹，确保它不会在不必要的情况下重建
  const init = useCallback(async () => {
    setIsLoading(true);
    setToast('正在开机...');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // 添加鉴权 token
      },
      body: JSON.stringify({
        now: '开机画面',
        next: '进入主屏幕',
      }),
    };

    const response = await fetch('/api/aiphone', requestOptions);
    const ai = await response.json();

    const { content, options } = ai;
    setTimeLine([{ content, options, choices: null }]);
    setIsLoading(false);
  }, [token]);

  // 页面返回主页
  const goHomePage = () => {
    setTimeLine([
      {
        time: 1,
        content: 'hello',
        options: [
          { text: '打开未读短信', cost: 21 },
          { text: '打开我订阅的新闻', cost: 21 },
          { text: '查看漂流瓶的通知', cost: 21 },
        ],
        choices: null,
      },
    ]);
    setHomePage(true);
  };

  // 选择选项
  const chooseOption = async (option) => {
    if (currentIndex !== 0 || isChoices) {
      return;
    }
    setIsChoices(true);
    setIsLoading(true);
    setToast(`${option.text}...`);

    const newTimeLine = [...timeLine];
    newTimeLine[currentIndex].choices = option.text;

    const history = newTimeLine.slice(1, 3).reverse().map((item) => {
      return `界面：${item.content}` + (item.choices ? ` 选择：${item.choices}` : '');
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // 添加鉴权 token
      },
      body: JSON.stringify({
        history,
        now: timeLine[currentIndex].content,
        choices: option.text,
      }),
    };

    const response = await fetch('/api/aiphone', requestOptions);
    const ai = await response.json();

    const { content, options } = ai;
    setTimeLine([{ content, options, choices: null }, ...timeLine]);
    setCurrentIndex(0);
    setIsLoading(false);
    setIsChoices(false);
    setHomePage(false);
  };

  // 使用 useEffect 在组件挂载时调用 init
  useEffect(() => {
    init(); // 组件初次挂载时调用 init
  }, [init]);
  // 渲染组件
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-xs border-4 border-white p-4 rounded-xl relative bg-gray-600 ">
        {/* 只在手机屏幕内显示加载蒙层 */}
        {isLoading && (
          <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center rounded-lg z-50">
            <div className="w-32 h-32 flex justify-center items-center rounded-lg">
              <span className="text-white text-lg">{toast}</span>
            </div>
          </div>
        )}

        {/* 手机主页面 */}
        {homePage ? (
          <img src="/aiphone/hand.png" className="w-60 h-60 rounded-xl" alt="home" />
        ) : (
          <div
            className="mt-0 overflow-auto scrollbar-none w-60 h-60 rounded-lg bg-green-400 p-3 box-border text-gray-800"
            dangerouslySetInnerHTML={{ __html: timeLine[currentIndex].content }}
          ></div>
        )}

        <div className="max-w-xs mx-auto">
          {timeLine.length > 0 &&
            timeLine[currentIndex].options.map((option, index) => (
              <div
                key={index}
                onClick={() => chooseOption(option)}
                className="p-2 mt-3 bg-gray-800 border border-gray-600 cursor-pointer transition ease-in-out duration-300 rounded-md text-gray-400 text-sm hover:bg-gray-700"
              >
                {option.text}
              </div>
            ))}
        </div>

        {/* 自定义输入选项 */}
        <div className="flex mb-3">
          <input
            value={customOption}
            onChange={(e) => setCustomOption(e.target.value)}
            placeholder="输入您的选择"
            className="p-2 mt-3 bg-gray-800 border border-gray-600 rounded-md text-gray-400 text-sm"
          />
          <div
            onClick={() => chooseOption({ text: customOption })}
            className="w-full text-center p-2 mt-3 bg-gray-800 border border-gray-600 cursor-pointer transition ease-in-out duration-300 rounded-md text-gray-400 text-sm whitespace-nowrap ml-1"
          >
            发送
          </div>
        </div>

        {/* 页面导航 */}
        <div className="flex justify-between items-center text-gray-500 text-base mt-2">
          <div
            onClick={() => setCurrentIndex(currentIndex + 1)}
            className={`${currentIndex === timeLine.length - 1
                ? 'text-gray-600 cursor-not-allowed'
                : 'cursor-pointer'
              }`}
          >
            上一步
          </div>
          <div className="cursor-pointer" onClick={goHomePage}>
            主页
          </div>
          <div
            onClick={() => setCurrentIndex(currentIndex - 1)}
            className={`${currentIndex === 0 ? 'text-gray-600 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            下一步
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
