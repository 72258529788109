// Assistant.js

import React, { useState, useEffect, useRef, useCallback } from 'react';

// 模型和工具定义
const availableModels = [
  'gpt-3.5-turbo-1106',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-3.5-turbo-0125',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307',
];

const tools = [
  {
    type: 'function',
    function: {
      description: '通过Google搜索获取指定查询的结果',
      name: 'getGoogleSearch',
      parameters: {
        type: 'object',
        properties: {
          query: {
            type: 'string',
            description: '要搜索的关键词或查询字符串',
          },
        },
        required: ['query'],
      },
    },
  },
  {
    type: 'function',
    function: {
      description: '解析指定URL并获取其内容',
      name: 'getUrl',
      parameters: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
            description: '要解析的网页的完整URL地址',
          },
        },
        required: ['url'],
      },
    },
  },
];

const Assistant = () => {
  const [userMessage, setUserMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini');
  const [isToolCall, setIsToolCall] = useState(false); // 新增状态，用于跟踪工具调用
  const lastMessageRef = useRef(null);

  // 记录 chatMessages 的变化
  useEffect(() => {
    console.log('当前的消息列表:', chatMessages);
  }, [chatMessages]);

  // runTool 函数：执行工具调用并更新消息列表
  const runTool = useCallback(async (tools) => {
    console.log('运行工具:', tools);
    let toolMessagesList = [];

    for (const item of tools) {
      let { id, name, arguments: args } = item;
      let funContent = '';

      // 将函数名映射到相应的 API 路径
      const apiMapping = {
        getGoogleSearch: '/api/assistant/tools/search',
        getUrl: '/api/assistant/tools/url',
      };

      try {
        // 根据函数名获取 API 路径
        const apiPath = apiMapping[name];

        if (!apiPath) {
          throw new Error(`未知的函数名: ${name}`);
        }

        // 发送 POST 请求到对应的 API 路径
        const response = await fetch(apiPath, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(JSON.parse(args)),
        });

        if (!response.ok) {
          throw new Error(`请求失败，状态码: ${response.status}`);
        }

        // 解析响应数据
        const data = await response.json();
        funContent = JSON.stringify(data);
      } catch (error) {
        funContent = `执行 ${name} 时出错: ${error.message}`;
      }

      // 构建工具调用的消息并添加到 toolMessagesList
      toolMessagesList.push({
        tool_call_id: id,
        role: 'function',
        name: name,
        content: funContent,
      });
    }

    // 使用函数式更新来确保基于最新的 chatMessages
    setChatMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, ...toolMessagesList];
      return updatedMessages;
    });

    // 设置标志，指示工具调用已完成
    setIsToolCall(true);
  }, []);

  // handleSendMessage 函数：发送消息到助理 API
  const handleSendMessage = useCallback(async () => {
    // 如果是工具调用后的更新，直接发送更新后的消息
    if (isToolCall) {
      setIsToolCall(false); // 重置标志
    }

    if (userMessage.trim() === '' && !isToolCall) return;

    const newUserMessage = userMessage.trim() !== '' ? { role: 'user', content: userMessage } : null;
    if (newUserMessage) {
      // 使用函数式更新
      setChatMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setUserMessage('');
    }
    setIsLoading(true);

    try {
      const response = await fetch('/api/assistant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: selectedModel,
          tools: tools,
          messages: newUserMessage ? [...chatMessages, newUserMessage] : chatMessages,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let assistantMessage = { role: 'assistant', content: '' };
      let toolCalls = []; // 用于存储多个工具调用

      // 使用函数式更新
      setChatMessages((prevMessages) => [...prevMessages, assistantMessage]);

      let buffer = '';
      let originalSSEData = ''; // 用于累加所有的 data 行

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            originalSSEData += line + '\n'; // 累加原始的 data 行

            const jsonData = line.slice(6).trim();
            if (jsonData === '[DONE]') {
              break;
            } else {
              try {
                const parsed = JSON.parse(jsonData);
                const delta = parsed.choices[0]?.delta;

                // 处理 delta.content
                if (delta?.content) {
                  assistantMessage.content += delta.content;
                  // 使用函数式更新
                  setChatMessages((prevMessages) => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1] = { ...assistantMessage };
                    return newMessages;
                  });
                }

                // 处理 delta.tool_calls
                if (delta?.tool_calls) {
                  for (const toolCallData of delta.tool_calls) {
                    const toolIndex = toolCallData.index;

                    // 查找是否已有该工具调用
                    let toolCall = toolCalls.find((t) => t.index === toolIndex);
                    if (!toolCall) {
                      // 初始化工具调用对象
                      toolCall = { index: toolIndex, arguments: '' };
                      toolCalls.push(toolCall);
                    }

                    // 保存 id 和 name（如果存在）
                    if (!toolCall.id && toolCallData.id) {
                      toolCall.id = toolCallData.id;
                    }
                    if (!toolCall.name && toolCallData.function?.name) {
                      toolCall.name = toolCallData.function.name;
                    }

                    // 累加 arguments，去除转义引号
                    const newArguments = toolCallData.function?.arguments;
                    if (newArguments) {
                      toolCall.arguments += newArguments.replace(/\\+"/g, '"');
                    }
                  }
                }

                // 处理 finish_reason
                const finishReason = parsed.choices[0]?.finish_reason;

                if (finishReason === 'tool_calls') {
                  // 在工具调用结束时，直接将 toolCalls 赋值给 assistantMessage
                  assistantMessage.tool_calls = toolCalls;

                  // 使用函数式更新，将 tool_calls 信息添加到消息列表中的最后一个 assistant 消息
                  setChatMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1] = { ...assistantMessage };
                    return updatedMessages;
                  });

                  // 处理所有已完成的工具调用
                  await runTool(toolCalls);

                  toolCalls = []; // 重置 toolCalls
                  return; // 结束当前函数，等待工具执行完毕后继续
                }

              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }
      }

      // 数据接收结束后，打印原始的 SSE 数据
      console.log('原始 SSE 数据：', originalSSEData);

    } catch (error) {
      setError(`发送消息失败: ${error.message}. 请再试一次。`);
    } finally {
      setIsLoading(false);
    }
  }, [userMessage, chatMessages, selectedModel, runTool, isToolCall]);

  // 使用 useEffect 监听 chatMessages 的变化，并在工具调用后继续发送消息
  useEffect(() => {
    if (isToolCall) {
      // 在工具调用完成并更新 chatMessages 后，继续发送更新后的消息
      handleSendMessage();
    }
  }, [chatMessages, isToolCall, handleSendMessage]);

  // 优化自动滚动逻辑
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages.length]);

  return (
    <div className="flex flex-col h-full">
      {/* 顶部：模型选择 */}
      <div className="my-4 flex justify-center">
        <select
          className="p-2 border border-gray-300 rounded-md"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          disabled={isLoading}
        >
          {availableModels.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>

      {/* 主体内容区域 */}
      <div className="flex-grow overflow-auto p-4 bg-gray-100">
        {/* 聊天消息 */}
        {chatMessages.map((message, index) => (
          <div key={index} ref={index === chatMessages.length - 1 ? lastMessageRef : null}>
            {message.role === 'user' && (
              <div className="flex justify-end mb-4">
                <div className="bg-gray-200 rounded-lg py-2 px-4 shadow break-all">{message.content}</div>
              </div>
            )}
            {message.role === 'assistant' && (
              <div className="flex justify-start mb-4">
                <div className="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">{message.content}</div>
              </div>
            )}
            {message.role === 'function' && (
              <div className="flex justify-start mb-4">
                <div className="bg-blue-100 text-gray-800 rounded-lg py-2 px-4 shadow break-words">
                  {`[${message.name}]: ${message.content}`}
                </div>
              </div>
            )}
          </div>
        ))}
        {isLoading && <div className="text-gray-500">AI 正在输入...</div>}
      </div>

      {/* 底部固定区域 */}
      <div className="bg-white w-full p-4 pt-1">
        <div className="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
          <textarea
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            rows="1"
            placeholder={isLoading ? 'AI正在思考...' : '向AI发送消息'}
            className="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200"
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isLoading}
          />
          <button
            onClick={handleSendMessage}
            disabled={isLoading || userMessage.trim() === ''}
            className={`text-2xl mr-1 ml-3 rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center ${
              isLoading || userMessage.trim() === '' ? 'bg-gray-400' : 'bg-black'
            }`}
          >
            {/* SVG 图标 */}
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 32 32">
              <path
                fill="white"
                fillRule="evenodd"
                d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* 显示错误信息 */}
      {error && <div className="text-red-500 p-2">{error}</div>}
    </div>
  );
};

export default Assistant;
